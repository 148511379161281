<template>
  <div>
    <ul class="list-unstyled mb-0" v-if="dataTimeline">
      <li :class="$style.item" v-for="(item, idx) in dataTimeline" :key="idx">
        <div class="mr-3 font-size-16">
          <div>{{ getDate(item.timestamp) }}</div>
          <div class="text-muted text-right">{{ getTime(item.timestamp) }}</div>
        </div>
        <div :class="$style.itemSeparator">
          <div :class="[$style.donut]" class="mr-3" />
        </div>
        <div class="mr-3">
          <div class="text-dark">{{ item.message }}</div>
          <div class="text-muted">{{ item.location }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ITrackCheckpoint } from '@/types/interfaces/IShipmentStatusTrack'
import { computed, defineComponent, PropType } from 'vue'
import { GetShortDateFormat, GetTimeFromDate } from '@/services/helpers'

export default defineComponent({
  name: 'TrackingTimeline',
  props: {
    checkpoints: {
      type: Object as PropType<ITrackCheckpoint[] | null>,
      default: () => null,
    },
  },
  setup(props) {
    const dataTimeline = computed(() => props.checkpoints)
    const getDate = (date: string) => {
      return GetShortDateFormat(date)
    }
    const getTime = (date: string) => {
      return GetTimeFromDate(date)
    }
    return { dataTimeline, getDate, getTime }
  },
})
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
