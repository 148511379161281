
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'HorizontalTableLoop',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    tableData: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const dataObject = computed(() => props.tableData)
    return {
      dataObject,
      props,
    }
  },
})
