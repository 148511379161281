
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'DataTableLoop',
  props: {
    tableData: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const dataObject = computed(() => props.tableData)
    return {
      dataObject,
      props,
    }
  },
})
