<template>
  <div v-if="canTrack">
    <a-skeleton :loading="isLoading" active>
      <div class="card card-top">
        <div>
          <div class="card-header py-0">
            <div class="card-header-flex align-items-center">
              <div class="mr-auto">
                <div class="d-flex flex-wrap align-items-center">
                  <div class="mr-2">
                    <span v-if="!trackingData?.latestStatus"
                      ><ExclamationCircleFilled :style="{ fontSize: '24px', color: '#D26759' }"
                    /></span>
                    <span v-else-if="checkIfIconValid(trackingData?.latestStatus, 'delivered')"
                      ><CheckCircleFilled :style="{ fontSize: '24px', color: '#4CBB87' }"
                    /></span>
                    <span v-else-if="checkIfIconValid(trackingData?.latestStatus, 'intransit')"
                      ><ClockCircleFilled :style="{ fontSize: '24px', color: '#65AEE0' }"
                    /></span>
                    <span v-else-if="checkIfIconValid(trackingData?.latestStatus, 'inforeceived')"
                      ><InfoCircleFilled :style="{ fontSize: '24px', color: '#65AEE0' }"
                    /></span>
                    <span v-else-if="checkIfIconValid(trackingData?.latestStatus, 'exception')"
                      ><ExclamationCircleFilled :style="{ fontSize: '24px', color: '#D26759' }"
                    /></span>
                    <span v-else
                      ><ClockCircleFilled :style="{ fontSize: '24px', color: '#65AEE0' }"
                    /></span>
                  </div>
                  <div>
                    <div
                      class="text-dark font-weight-bold font-size-18 pr-3"
                      style="border-right: 2px solid #f0f0f0;"
                    >
                      {{ trackingId }}
                    </div>
                  </div>
                  <div>
                    <div
                      class="text-dark font-size-18 pr-3 pl-3"
                      style="border-right: 2px solid #f0f0f0;"
                    >
                      {{ trackingData?.latestStatus || 'Not Found' }}
                    </div>
                  </div>
                  <div v-if="trackingData?.latestStatus">
                    <div
                      class="text-dark font-size-18 pr-3 pl-3"
                      style="border-right: 2px solid #f0f0f0;"
                    >
                      In Transit for {{ trackingData?.deliveryDays || 0 }} Days
                    </div>
                  </div>
                  <div v-if="trackingData?.latestStatus">
                    <div class="pr-3 pl-3">
                      <a
                        class="btn btn-link font-size-18 p-0"
                        :href="trackingData?.courierTrackingLink || '#'"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ trackingData?.courierName || 'Courier Unavailable' }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <!-- BUTTONS -->
                  <button-group-actions
                    :button-list="actionButtonList"
                    @handleRefreshClick="handleRefresh"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="trackingData?.checkpoint?.length > 0">
            <div class="row">
              <div class="col-md-7">
                <!-- Timeline -->
                <div style="border-right: 1px solid #f0f0f0;">
                  <tracking-timeline :checkpoints="trackingData?.checkpoint" />
                </div>
              </div>
              <div class="col-md-5">
                <data-table-loop :table-data="otherInfo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-skeleton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from 'vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import * as actions from '@/store/actions.type'
import { useStore } from 'vuex'
import { IShipmentStatusTrack } from '@/types/interfaces/IShipmentStatusTrack'
import { ShipmmentTrackContract } from '@/types/appcontracts/ShipmentTrackContract'
import TrackingTimeline from './ListTimeline/index.vue'
import DataTableLoop from './DataTableLoop.vue'

import {
  ClockCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import { GetShortDateFormat, isAliasEqual } from '@/services/helpers'
export default defineComponent({
  name: 'ShipmentTrackingView',
  components: {
    ButtonGroupActions,
    TrackingTimeline,
    ClockCircleFilled,
    CheckCircleFilled,
    InfoCircleFilled,
    ExclamationCircleFilled,
    DataTableLoop,
  },
  props: {
    awb: {
      type: String,
      default: () => null,
    },
    slug: {
      type: String,
      default: () => null,
    },
    viewTracking: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const store = useStore()
    const canTrack = computed(() => props.viewTracking)
    const trackingId = computed(() => props.awb)
    const courierSlug = computed(() => props.slug)
    const trackingData = ref<IShipmentStatusTrack | null>(null)
    const isLoading = ref<boolean>(false)
    const actionButtonList = [ActionButtonList.REFRESH]

    const getShipmentTracking = async (trackId: string | null, slg: string | null) => {
      if (!trackId || !slg) {
        trackingData.value = null
      } else {
        isLoading.value = true
        const resp: IShipmentStatusTrack | null = await store.dispatch(
          `consignments/${actions.ConsignmentsAction.GET_SHIPMENT_TRACKING}`,
          {
            awb: trackId,
            slug: slg,
          } as ShipmmentTrackContract,
        )
        trackingData.value = resp
        isLoading.value = false
      }
    }

    const checkIfIconValid = (status: string | null, iconName: string) => {
      if (isAliasEqual(status, 'Delivered') && iconName === 'delivered') {
        return true
      }
      if (isAliasEqual(status, 'intransit') && iconName === 'intransit') {
        return true
      }
      if (isAliasEqual(status, 'exception') && iconName === 'exception') {
        return true
      }
      if (isAliasEqual(status, 'InfoReceived') && iconName === 'inforeceived') {
        return true
      }
      return false
    }

    const otherInfo = computed(() => {
      const delv =
        trackingData.value?.deliveryDate || trackingData.value?.expectedDeliveryDate || null
      return {
        'From Location': trackingData.value?.originaLocation || 'N/A',
        'To Location': trackingData.value?.deliveryLocation || 'N/A',
        'Signed By': trackingData.value?.signatureName || 'N/A',
        'Delivery Date': delv ? GetShortDateFormat(delv) : 'N/A',
      }
    })

    const handleRefresh = async () => {
      await getShipmentTracking(trackingId.value, courierSlug.value)
    }

    watchEffect(async () => {
      if (canTrack.value) {
        await getShipmentTracking(trackingId.value, courierSlug.value)
      }
    })

    return {
      actionButtonList,
      trackingData,
      isLoading,
      trackingId,
      checkIfIconValid,
      otherInfo,
      handleRefresh,
      canTrack,
    }
  },
})
</script>

<style scoped></style>
