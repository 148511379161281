
import { ITrackCheckpoint } from '@/types/interfaces/IShipmentStatusTrack'
import { computed, defineComponent, PropType } from 'vue'
import { GetShortDateFormat, GetTimeFromDate } from '@/services/helpers'

export default defineComponent({
  name: 'TrackingTimeline',
  props: {
    checkpoints: {
      type: Object as PropType<ITrackCheckpoint[] | null>,
      default: () => null,
    },
  },
  setup(props) {
    const dataTimeline = computed(() => props.checkpoints)
    const getDate = (date: string) => {
      return GetShortDateFormat(date)
    }
    const getTime = (date: string) => {
      return GetTimeFromDate(date)
    }
    return { dataTimeline, getDate, getTime }
  },
})
