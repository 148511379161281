
import { computed, defineComponent, ref, watchEffect } from 'vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import * as actions from '@/store/actions.type'
import { useStore } from 'vuex'
import { IShipmentStatusTrack } from '@/types/interfaces/IShipmentStatusTrack'
import { ShipmmentTrackContract } from '@/types/appcontracts/ShipmentTrackContract'
import TrackingTimeline from './ListTimeline/index.vue'
import DataTableLoop from './DataTableLoop.vue'

import {
  ClockCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue'
import { GetShortDateFormat, isAliasEqual } from '@/services/helpers'
export default defineComponent({
  name: 'ShipmentTrackingView',
  components: {
    ButtonGroupActions,
    TrackingTimeline,
    ClockCircleFilled,
    CheckCircleFilled,
    InfoCircleFilled,
    ExclamationCircleFilled,
    DataTableLoop,
  },
  props: {
    awb: {
      type: String,
      default: () => null,
    },
    slug: {
      type: String,
      default: () => null,
    },
    viewTracking: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const store = useStore()
    const canTrack = computed(() => props.viewTracking)
    const trackingId = computed(() => props.awb)
    const courierSlug = computed(() => props.slug)
    const trackingData = ref<IShipmentStatusTrack | null>(null)
    const isLoading = ref<boolean>(false)
    const actionButtonList = [ActionButtonList.REFRESH]

    const getShipmentTracking = async (trackId: string | null, slg: string | null) => {
      if (!trackId || !slg) {
        trackingData.value = null
      } else {
        isLoading.value = true
        const resp: IShipmentStatusTrack | null = await store.dispatch(
          `consignments/${actions.ConsignmentsAction.GET_SHIPMENT_TRACKING}`,
          {
            awb: trackId,
            slug: slg,
          } as ShipmmentTrackContract,
        )
        trackingData.value = resp
        isLoading.value = false
      }
    }

    const checkIfIconValid = (status: string | null, iconName: string) => {
      if (isAliasEqual(status, 'Delivered') && iconName === 'delivered') {
        return true
      }
      if (isAliasEqual(status, 'intransit') && iconName === 'intransit') {
        return true
      }
      if (isAliasEqual(status, 'exception') && iconName === 'exception') {
        return true
      }
      if (isAliasEqual(status, 'InfoReceived') && iconName === 'inforeceived') {
        return true
      }
      return false
    }

    const otherInfo = computed(() => {
      const delv =
        trackingData.value?.deliveryDate || trackingData.value?.expectedDeliveryDate || null
      return {
        'From Location': trackingData.value?.originaLocation || 'N/A',
        'To Location': trackingData.value?.deliveryLocation || 'N/A',
        'Signed By': trackingData.value?.signatureName || 'N/A',
        'Delivery Date': delv ? GetShortDateFormat(delv) : 'N/A',
      }
    })

    const handleRefresh = async () => {
      await getShipmentTracking(trackingId.value, courierSlug.value)
    }

    watchEffect(async () => {
      if (canTrack.value) {
        await getShipmentTracking(trackingId.value, courierSlug.value)
      }
    })

    return {
      actionButtonList,
      trackingData,
      isLoading,
      trackingId,
      checkIfIconValid,
      otherInfo,
      handleRefresh,
      canTrack,
    }
  },
})
