<template>
  <div>
    <div>
      <div class="pb-2">
        <p class="text-uppercase text-dark font-weight-bold mb-1">
          {{ props.title || 'N/A' }}
        </p>
        <p class="text-gray-5 mb-0">
          {{ props.description || 'N/A' }}
        </p>
      </div>
      <div>
        <div class="table-responsive text-nowrap">
          <table class="table table-borderless">
            <tbody>
              <tr v-for="(value, name, idx) in dataObject" :key="idx">
                <td class="text-gray-6 pl-0">{{ name || 'N/A' }}</td>
                <td class="pr-0 text-left text-dark">
                  {{ value || 'N/A' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'HorizontalTableLoop',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    tableData: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup(props) {
    const dataObject = computed(() => props.tableData)
    return {
      dataObject,
      props,
    }
  },
})
</script>

<style lang="scss" scoped>
.table th,
.table td {
  padding: 0.35rem;
}
</style>
