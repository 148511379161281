
import { computed, defineComponent, ref } from 'vue'
import ButtonGroupActions from '@/components/General/ButtonGroupActions.vue'
import { ActionButtonList } from '@/types/enums/ActionButtonList'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import * as actions from '@/store/actions.type'
import { useStore } from 'vuex'
import { formatAddressObject, GetDateFromNow, GetShortDateFormat } from '@/services/helpers'
import HorizontalTableLoop from '@/components/General/HorizontalTableLoop.vue'
import DataTableLoop from './DataTableLoop.vue'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { useRouter } from 'vue-router'
import ShipmentTrackingView from './ShipmentTrackingView.vue'

export default defineComponent({
  name: 'ShipmentTrack',
  components: {
    ButtonGroupActions,
    HorizontalTableLoop,
    DataTableLoop,
    ShipmentTrackingView,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const searchLoading = ref<boolean>(false)
    const searchValue = ref<string | null>(null)
    const shipmentData = ref<ConsignmentsDocument | null>(null)
    const dataList = ref<ConsignmentsDocument[] | null>(null)
    const selectedAwb = ref<string | null>(null)
    const awbOptions = computed(() =>
      dataList.value?.map((x) => {
        return {
          value: x.awb,
          label: x.awb,
        }
      }),
    )
    const actionButtonList = [
      ActionButtonList.VIEW,
      ActionButtonList.EDIT,
      ActionButtonList.REFRESH,
    ]
    const trackingId = computed(() => shipmentData.value?.awb)
    const viewTracking = ref<boolean>(false)
    const { getVendorByIdAlias, getServiceByIdAlias, getCustomerById } = useSearchMapping()
    const vendorSlug = computed(
      () => (getVendorByIdAlias(shipmentData.value?.vendorId || null, null) || null)?.slug,
    )

    const fetchShipmentData = async (awb: string | null) => {
      if (!awb) {
        return null
      }
      const resp: ConsignmentsDocument[] | null = await store.dispatch(
        `consignments/${actions.ConsignmentsAction.FETCH_CONSIGNMENT_DATA_ID}`,
        {
          trackId: awb,
        },
      )
      return resp
    }

    const handleSearchEnter = async (trackingId: string | null) => {
      searchLoading.value = true
      selectedAwb.value = null
      viewTracking.value = false
      dataList.value = await fetchShipmentData(trackingId)
      shipmentData.value = dataList?.value?.[0] || null
      selectedAwb.value = shipmentData.value?.awb || null
      searchLoading.value = false
    }

    const auditData = computed(() => {
      const aud = shipmentData?.value?.audit
      return {
        'Created By': aud?.createdByEmail,
        'Created On': `${GetDateFromNow(aud?.createdOn.toDate())} (${GetShortDateFormat(
          aud?.createdOn.toDate(),
        )})`,
        'Modified By': aud?.modifiedByEmail,
        'Modified On': `${GetDateFromNow(aud?.modifiedOn.toDate())} (${GetShortDateFormat(
          aud?.modifiedOn.toDate(),
        )})`,
        'Import ID': aud?.importId,
      }
    })

    const basicInfo = computed(() => {
      return {
        RFN: shipmentData.value?.rfn,
        Type: shipmentData.value?.docType?.toUpperCase(),
        'Weight(kg)': shipmentData.value?.weight,
        Pcs: shipmentData.value?.pcs,
        Dimensions: `${shipmentData.value?.dimensions.l}x${shipmentData.value?.dimensions.b}x${shipmentData.value?.dimensions.h}`,
      }
    })

    const mainInfo = computed(() => {
      const cust = getCustomerById(shipmentData.value?.customerId || null, null) || null
      const vendor = getVendorByIdAlias(shipmentData.value?.vendorId || null, null) || null
      const service = getServiceByIdAlias(shipmentData.value?.serviceId || null, null) || null
      const carrier = getVendorByIdAlias(shipmentData.value?.carrierId || null, null) || null
      return {
        'Booking Date': GetShortDateFormat(shipmentData.value?.bookingDate as Date),
        Customer: cust ? `${cust.displayName} (${cust.cid})` : 'N/A',
        Vendor: vendor ? `${vendor.displayName}` : 'N/A',
        Service: service ? `${service.name}` : 'N/A',
        Carrier: carrier ? `${carrier.displayName}` : 'N/A',
      }
    })

    const addressInfo = computed(() => {
      const dest = shipmentData?.value?.destinationAddress
        ? formatAddressObject(shipmentData?.value?.destinationAddress)
        : null
      const invId = shipmentData.value?.invoiceId?.split('_')
      const inv = invId?.[0]
      return {
        'Amount(INR)': shipmentData.value?.amount,
        Destination: dest,
        'Invoice ID': inv || 'N/A',
        'Pre Paid': shipmentData.value?.paymentMode ? 'YES' : 'NO',
      }
    })

    const handleRefresh = async (resetTrack: boolean = false) => {
      if (selectedAwb.value) {
        // await handleSearchEnter(shipmentData.value.awb, false)
        searchLoading.value = true
        if (resetTrack) {
          viewTracking.value = false
        }
        shipmentData.value = (await fetchShipmentData(selectedAwb.value))?.[0] || null
        searchLoading.value = false
      }
    }

    const handleAwbSelect = async (awb: string) => {
      if (awb) {
        await handleRefresh(true)
      }
    }

    const handleEdit = () => {
      if (shipmentData.value?.awb) {
        router.push({ name: 'consignmentEdit', params: { id: shipmentData.value.awb } })
      }
    }

    const handleView = () => {
      viewTracking.value = true
    }

    const getCourierTrackingLink = () => {
      const awb = shipmentData.value?.awb
      switch (vendorSlug.value) {
        case 'bluedart':
          return `http://bluedart.in/?${awb}`
        case 'dhl':
          return `https://mydhl.express.dhl/us/en/tracking.html#/results?id=${awb}`
        case 'dtdc':
          return `https://tracking.dtdc.com/ctbs-tracking/customerInterface.tr?submitName=showCITrackingDetails&cType=Consignment&cnNo=${awb}`
        case 'spice':
          return ` https://www.spicexpress.com/getSingleAwbDetails?tracking_id=${awb?.substring(3) || ''}`
        default:
          return '#'
      }
    }

    return {
      actionButtonList,
      searchValue,
      handleSearchEnter,
      shipmentData,
      searchLoading,
      auditData,
      basicInfo,
      mainInfo,
      addressInfo,
      handleRefresh,
      handleEdit,
      trackingId,
      vendorSlug,
      viewTracking,
      handleView,
      awbOptions,
      handleAwbSelect,
      selectedAwb,
      getCourierTrackingLink,
    }
  },
})
